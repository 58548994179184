import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login_view",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      deepth: 0.1, // 定义路由的层级
    },
  },
  {
    path: "/change_pass",
    name: "change_pass",
    meta: {
      deepth: 0.5, // 定义路由的层级
    },
    component: () => import("@/views/change_pass.vue"),
  },
  {
    path: "/",
    name: "home_page",
    meta: {
      keepAlive: true,
      deepth: 0.5, // 定义路由的层级
    },
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/editchangyong",
    name: "editchangyong",
    component: () => import("@/views/EditChangYongView.vue"),
  },
  {
    path: "/feed_back",
    name: "feed_back",
    component: () => import("@/views/feed_back"),
  },
  {
    path: "/other_page",
    name: "other_page",
    meta: {
      keepAlive: true,
      deepth: 0.8, // 定义路由的层级
    },
    component: () => import("@/views/other_page.vue"),
  },
  {
    path: "/jiage_fangan",
    name: "jiage_fangan",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/jiagefangan/index"),
  },
  {
    path: "/view_jgfa",
    name: "view_jgfa",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/jiagefangan/view_jgfa"),
  },
  {
    path: "/select_tree",
    name: "select_tree",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/jiagefangan/select_tree"),
  },
  {
    path: "/huodong",
    name: "huo_dong",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/huodong/index"),
  },
  {
    path: "/view_huodong",
    name: "view_huodong",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/huodong/view_huodong"),
  },
  {
    path: "/cui_kuan",
    name: "cui_kuan",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/cuikuan/index"),
  },
  {
    path: "/view_invoice_record",
    name: "view_invoice_record",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/cuikuan/view_invoice_record"),
  },
  {
    path: "/my_cuikuan_prj",
    name: "my_cuikuan_prj",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/cuikuan/my_cuikuan_prj"),
  },
  {
    path: "/my_cuikuan_prj_follow",
    name: "my_cuikuan_prj_follow",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/cuikuan/my_cuikuan_prj_follow"),
  },
  {
    path: "/my_cuikuan_prj_follow_add",
    name: "my_cuikuan_prj_follow_add",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/cuikuan/my_cuikuan_prj_follow_add"),
  },
  {
    path: "/view_record",
    name: "view_record",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/cuikuan/view_record"),
  },
  {
    path: "/use_case",
    name: "use_case",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/usecase/index"),
  },
  {
    path: "/edit_usecase",
    name: "edit_usecase",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/usecase/edit_usecase"),
  },
  {
    path: "/sale_incentive",
    name: "sale_incentive",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/saleincentive/index"),
  },
  {
    path: "/gan_ran",
    name: "gan_ran",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/ganran/index"),
  },
  {
    path: "/view_ganran",
    name: "view_ganran",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/ganran/view_ganran"),
  },
  {
    path: "/view_ganran_custom",
    name: "view_ganran_custom",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/ganran/view_ganran_custom"),
  },
  {
    path: "/aav_yingyong",
    name: "aav_yingyong",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/aav/index"),
  },
  {
    path: "/train_video",
    name: "train_video",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/trainvideo/index"),
  },
  {
    path: "/play_video",
    name: "play_video",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/trainvideo/play_video"),
  },
  {
    path: "/gene_query",
    name: "gene_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/genequery/index"),
  },
  {
    path: "/gene_query_result",
    name: "gene_query_result",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/genequery/gene_query_result"),
  },
  {
    path: "/gene_detail",
    name: "gene_detail",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/genequery/gene_detail"),
  },
  {
    path: "/sby_pinggu",
    name: "sby_pinggu",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/sby/index"),
  },
  {
    path: "/sby_result",
    name: "sby_result",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/sby/sby_result"),
  },
  {
    path: "/xianhuo_query",
    name: "xianhuo_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/xianhuo/index"),
  },
  {
    path: "/view_duzhong",
    name: "view_duzhong",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/xianhuo/view_duzhong"),
  },
  {
    path: "/view_zhili",
    name: "view_zhili",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/xianhuo/view_zhili"),
  },
  {
    path: "/after_sale",
    name: "after_sale",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/aftersale/index"),
  },
  {
    path: "/view_aftersale_record",
    name: "view_aftersale_record",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/aftersale/view_aftersale_record"),
  },
  {
    path: "/hua_shu",
    name: "hua_shu",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/jpdb/index"),
  },
  {
    path: "/view_jpdb",
    name: "view_jpdb",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/jpdb/view_jpdb"),
  },
  {
    path: "/my_customer",
    name: "my_customer",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/customer/index"),
  },
  {
    path: "/view_customer",
    name: "view_customer",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/customer/view_customer"),
  },
  {
    path: "/edit_customer",
    name: "edit_customer",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/customer/edit_customer"),
  },
  {
    path: "/select_pi",
    name: "select_pi",
    meta: {
      keepAlive: true,
      deepth: 4,
    },
    component: () => import("@/views/customer/select_pi"),
  },
  {
    path: "/wu_liao",
    name: "wu_liao",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/wuliao/index"),
  },
  {
    path: "/add_apply",
    name: "add_apply",
    meta: {
      deepth: 2,
    },
    component: () => import("@/views/wuliao/add_apply"),
  },
  {
    path: "/view_wuliao_apply",
    name: "view_wuliao_apply",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/wuliao/view_wuliao_apply"),
  },
  {
    path: "/view_wuliao_apply_detail",
    name: "view_wuliao_apply_detail",
    meta: {
      deepth: 4,
    },
    component: () => import("@/views/wuliao/view_wuliao_apply_detail"),
  },
  {
    path: "/sale_report",
    name: "sale_report",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/salereport/index"),
  },
  {
    path: "/day_report_list",
    name: "day_report_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/salereport/day_report_list"),
  },
  {
    path: "/add_report",
    name: "add_report",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/salereport/add_report"),
  },
  {
    path: "/my_project",
    name: "my_project",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/myproject/index"),
  },
  {
    path: "/add_project_0",
    name: "add_project_0",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_0"),
  },
  {
    path: "/add_project_1",
    name: "add_project_1",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_1"),
  },
  {
    path: "/add_project_5",
    name: "add_project_5",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_5"),
  },
  {
    path: "/add_project_11",
    name: "add_project_11",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_11"),
  },
  {
    path: "/add_project_12",
    name: "add_project_12",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_12"),
  },
  {
    path: "/add_project_13",
    name: "add_project_13",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/myproject/add_project_13"),
  },
  {
    path: "/invoice_manage",
    name: "invoice_manage",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/invoice/invoice_manage"),
  },
  {
    path: "/invoice_edit",
    name: "invoice_edit",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/invoice/invoice_edit"),
  },
  {
    path: "/view_prj",
    name: "view_prj",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/myproject/view_prj"),
  },
  {
    path: "/view_prj_pdu",
    name: "view_prj_pdu",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/myproject/view_prj_pdu"),
  },
  {
    path: "/view_deliver",
    name: "view_deliver",
    meta: {
      keepAlive: true,
      deepth: 4,
    },
    component: () => import("@/views/myproject/view_deliver"),
  },
  {
    path: "/view_try_feedback",
    name: "view_try_feedback",
    meta: {
      keepAlive: true,
      deepth: 4,
    },
    component: () => import("@/views/myproject/view_try_feedback"),
  },
  {
    path: "/select_storage",
    name: "select_storage",
    meta: {
      keepAlive: true,
      deepth: 5,
    },
    component: () => import("@/views/myproject/select_storage"),
  },
  {
    path: "/my_coupon",
    name: "my_coupon",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/mycoupon/index"),
  },
  {
    path: "/ye_ji",
    name: "ye_ji",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/yeji/index"),
  },
  {
    path: "/question_list",
    name: "question_list",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/question/index"),
  },
  {
    path: "/question_detail",
    name: "question_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/question/question_detail"),
  },
  {
    path: "/add_question",
    name: "add_question",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/question/add_question"),
  },
  {
    path: "/jianxi_query",
    name: "jianxi_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/jianxi/index"),
  },
  {
    path: "/storage_query",
    name: "storage_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/storage/index"),
  },
  {
    path: "/product_query",
    name: "product_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/product/index"),
  },
  {
    path: "/paper_query",
    name: "paper_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/paper/index"),
  },
  {
    path: "/paper_detail",
    name: "paper_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/paper/paper_detail"),
  },
  {
    path: "/ctm_require",
    name: "ctm_require",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/ctm/ctm_require"),
  },
  {
    path: "/ctm_stuff",
    name: "ctm_stuff",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/ctm/ctm_stuff"),
  },
  {
    path: "/ctm_stuff_detail",
    name: "ctm_stuff_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/ctm/ctm_stuff_detail"),
  },
  {
    path: "/ctm_contract_feedback",
    name: "ctm_contract_feedback",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/ctm/ctm_contract_feedback"),
  },
  {
    path: "/ctm_contract_feedback_list",
    name: "ctm_contract_feedback_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/ctm/ctm_contract_feedback_list"),
  },
  {
    path: "/ctm_contract_feedback_detail",
    name: "ctm_contract_feedback_detail",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/ctm/ctm_contract_feedback_detail"),
  },
  {
    path: "/ctm_holiday_gift",
    name: "ctm_holiday_gift",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/ctm/ctm_holiday_gift"),
  },
  {
    path: "/ctm_holiday_gift_detail",
    name: "ctm_holiday_gift_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/ctm/ctm_holiday_gift_detail"),
  },
  {
    path: "/express_query",
    name: "express_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/express/index"),
  },
  {
    path: "/my_kpi",
    name: "my_kpi",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/my_kpi/index"),
  },
  {
    path: "/kpi_detail",
    name: "kpi_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/my_kpi/kpi_detail"),
  },
  {
    path: "/zaiti_query",
    name: "zaiti_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/zaiti/index"),
  },
  {
    path: "/oligo_query",
    name: "oligo_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/oligo/index"),
  },
  {
    path: "/oligo_detail",
    name: "oligo_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/oligo/oligo_detail"),
  },
  {
    path: "/deliver_query",
    name: "deliver_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/deliver/index"),
  },
  {
    path: "/deliver_detail",
    name: "deliver_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/deliver/deliver_detail"),
  },
  {
    path: "/doc_query",
    name: "doc_query",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/doc/index"),
  },
  {
    path: "/view_doc",
    name: "view_doc",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/doc/view_doc"),
  },
  {
    path: "/gift_check",
    name: "gift_check",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/gift/gift_check"),
  },
  {
    path: "/gift_check_detail",
    name: "gift_check_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/gift/gift_check_detail"),
  },
  {
    path: "/crm_index",
    name: "crm_index",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/crm/crm_index"),
  },
  {
    path: "/crm_add",
    name: "crm_add",
    meta: {
      keepAlive: true,
      deepth: 4,
    },
    component: () => import("@/views/crm/crm_add"),
  },
  {
    path: "/crm_follow",
    name: "crm_follow",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/crm/crm_follow"),
  },
  {
    path: "/crm_detail",
    name: "crm_detail",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/crm/crm_detail"),
  },
  {
    path: "/crm_add_follow",
    name: "crm_add_follow",
    meta: {
      keepAlive: true,
      deepth: 5,
    },
    component: () => import("@/views/crm/crm_add_follow"),
  },
  {
    path: "/flow_index",
    name: "flow_index",
    meta: {
      keepAlive: true,
      deepth: 1,
    },
    component: () => import("@/views/flow/index"),
  },
  {
    path: "/create_flow",
    name: "create_flow",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/flow/create_flow"),
  },
  {
    path: "/my_flow/:state",
    name: "my_flow",
    meta: {
      keepAlive: true,
      deepth: 3,
    },
    component: () => import("@/views/flow/my_flow"),
  },
  {
    path: "/need_review_flow",
    name: "need_review_flow",
    meta: {
      keepAlive: true,
      deepth: 4,
    },
    component: () => import("@/views/flow/need_review_flow"),
  },
  {
    path: "/process_flow",
    name: "process_flow",
    meta: {
      deepth: 5,
    },
    component: () => import("@/views/flow/process_flow"),
  },
  {
    path: "/view_entity",
    name: "view_entity",
    meta: {
      deepth: 6,
    },
    component: () => import("@/views/flow/view_entity"),
  },
  {
    path: "/view_flow",
    name: "view_flow",
    meta: {
      deepth: 7,
    },
    component: () => import("@/views/flow/view_flow"),
  },
  {
    path: "/cc_flow",
    name: "cc_flow",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/flow/cc_flow"),
  },
  {
    path: "/reviewed_flow",
    name: "reviewed_flow",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/flow/reviewed_flow"),
  },
  {
    path: "/my_attendance",
    name: "my_attendance",
    meta: {
      deepth: 1,
    },
    component: () => import("@/views/attendance/index"),
  },
  {
    path: "/stock_index",
    name: "stock_index",
    meta: {
      deepth: 1,
    },
    component: () => import("@/views/stock/index"),
  },
  {
    path: "/stock/mypurchaselist",
    name: "my_purchase_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/stock/my_purchase_list"),
  },
  {
    path: "/stock/myuselist",
    name: "my_use_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/stock/my_use_list"),
  },
  {
    path: "/stock/storageconfirmlist",
    name: "storage_confirm_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/stock/storage_confirm_list"),
  },
  {
    path: "/stock/useconfirmlist",
    name: "use_confirm_list",
    meta: {
      keepAlive: true,
      deepth: 2,
    },
    component: () => import("@/views/stock/use_confirm_list"),
  },
  {
    path: "/stock/prsummarylist",
    name: "pr_summary_list",
    meta: {     
      deepth: 2,
    },
    component: () => import("@/views/stock/pr_summary_list"),
  },
  {
    path: "/pr_confirm",
    name: "pr_confirm",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/stock/pr_confirm"),
  },
  {
    path: "/select_purchase_product",
    name: "select_purchase_product",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/stock/select_purchase_product"),
  },
  {
    path: "/select_use_product",
    name: "select_use_product",
    meta: {
      deepth: 3,
    },
    component: () => import("@/views/stock/select_use_product"),
  },
];

const router = new VueRouter({
  routes,
  //mode: "history"
});

export default router;
